<template>
  <page>
    <module enable-flex>
      <m-search ref="search" @search="searchHandle">
        <m-search-item label="班组名称">
          <c-input name="team_name"></c-input>
        </m-search-item>
      </m-search>

      <m-operate>
        <c-button v-if="auth('b_team_edit')" color="sys" @click="createTeam">新建班组</c-button>
      </m-operate>

      <c-table ref="table" height="grow">

        <c-table-column
            label="班组名称"
            width="200"
            name="team_name"
        ></c-table-column>


        <c-table-column
            label="备注"
            width="300"
            name="remark"
        >
          <template #default="{data}">
            <span v-if="data.remark">{{data.remark}}</span>
            <span v-else>--</span>
          </template>
        </c-table-column>

		<c-table-column
		    label="创建时间"
		    width="140"
		    name="create_date"
		></c-table-column>

        <c-table-column
            type="button"
            label="操作"
            width="120"
        >
          <template v-slot="{data}">
            <c-table-button v-if="auth('b_team_edit')" @click="editTeam(data)">编辑</c-table-button>
			<c-table-button v-if="auth('b_team_edit')" @click="deleteTeam(data)">删除</c-table-button>
          </template>
        </c-table-column>
      </c-table>
    </module>

    <c-dialog ref="teamEdit" :title="editState ? '编辑班组' : '新建班组'" width="600" @resolve="submit">
      <c-form ref="teamForm" unit-width="100%">
        <c-form-item label="班组名称" required>
          <c-input name="team_name"></c-input>
        </c-form-item>
        <c-form-item label="备注">
	        <c-input type="textarea" name="remark"></c-input>
        </c-form-item>
        <c-input type="hidden" name="id"></c-input>
      </c-form>
    </c-dialog>

  </page>
</template>

<script>
export default {
  name: 'm_team',
  components: {
  },
  data() {
    return {
      editState: false
    }
  },
  computed: {
  },
  mounted() {
    this.$refs.search.search();
  },
  methods: {
    tabHandle(item) {
      this.$refs.search.search();
    },
    searchHandle(data) {
      this.$refs.table.load({
        url: '/dic/team/list',
        data
      });
    },
    //创建班组
    createTeam() {
      this.editState = false;
      this.$refs.teamForm.clear();
      this.$refs.teamForm.set({});
      this.$refs.teamEdit.open();
    },
    //编辑班组
    editTeam(val) {
      this.editState = true;
      this.$refs.teamForm.set(val);
      this.$refs.teamEdit.open();
    },
	//删除班组
	deleteTeam(val){
		this.$confirm({
		  title: '确认',
		  message: '确定要删除班组【'+val.team_name+'】吗？',  // 提示内容,
		  buttonName: '确定',  // 确定按钮文字
		  cancelButton: true,  // 是否显示取消按钮
		  resolve: () => {
		    this.request({
		      url: '/dic/team/delete',
		      data: {id:val.id},
		      loading: true,
		      success: data => {
		        this.$message({
		          message: '删除成功',
		          type: 'success'
		        });
				this.$refs.table.update();
		      }
		    });
		  },  // 确定按钮回调
		  cancel: () => {},  // 取消按钮回调
		});
	},
    //提交保存
    submit(stop) {
      stop();
      this.$refs.teamForm.submit({
        url: this.editState ? '/dic/team/update' : '/dic/team/add',
        rule: {
          team_name: '请填写班组名'
        },
        dataFormatter: data => {
          return data;
        },
        success: (data, res) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$refs.table.update();
          this.$refs.teamEdit.close();
        }
      });
    }
  }
}
</script>